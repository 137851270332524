import React, {useState} from 'react';
import {Button, Input} from 'reactstrap';
import './UrlDecoder.scss';

function UrlDecoder() {
    const [text, setText] = useState('');

    const encode = () => {
        setText(encodeURIComponent(text))
    }

    const decode = () => {
        setText(decodeURIComponent(text))
    }

    return (
        <div className="url-encoder">
            <Input type="textarea" placeholder="" onChange={(event) => setText(event.target.value)} value={text} name="text" className="mb-3"/>
            <Button color="primary" onClick={encode} className="mr-3 encode-btn">URL Encode</Button>
            <Button color="primary" onClick={decode} className="decode-btn">URL Decode</Button>
        </div>
    )
}


/*
class UrlDecoder extends React.Component {
    constructor(props) {
        super(props);

        this.encode = this.encode.bind(this);
        this.decode = this.decode.bind(this);

        this.state = {
            text: ''
        };
    }

    updateTextState(e) {
        this.setState({text: e.currentTarget.value});
    }

    encode() {
        this.setState({text: encodeURIComponent(this.state.text)})
    }

    decode() {
        this.setState({text: decodeURIComponent(this.state.text)})
    }

    render() {
        return (
            <div className="url-encoder">
                <Input type="textarea" placeholder="" onChange={(event) => this.updateTextState(event)}
                       value={this.state.text} name="text" className="mb-3"/>

                <Button color="primary" onClick={this.encode} className="mr-3">URL Encode</Button>
                <Button color="primary" onClick={this.decode}>URL Decode</Button>

            </div>
        )
    }
}
*/

export default UrlDecoder;