import React from 'react';
import {Container, Row, Col, CardHeader, CardBody, Card} from 'reactstrap';
import UrlDecoder from 'components/url_encoder/UrlDecoder.js'
import Base64Encoder from 'components/base64_encoder/Base64Encoder.js'
import TimeConverter from 'components/time_converter/TimeConverter.js'
import JsonFormatter from 'components/json_formatter/JsonFormatter.js'
import CodeDiff from "components/code_diff/CodeDiff";
import './App.scss';
import RegexPal from "components/regex_pal/RegexPal";


function App() {
    return (
        <Container fluid={true}>
            <Row>
                <Col className="mb-5" xs="12" sm="6">
                    <Card className="height-100 xs-100-vh">
                        <CardHeader>JSON Lint</CardHeader>
                        <CardBody>
                            <JsonFormatter></JsonFormatter>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="mb-5" xs="12" sm="6">
                    <Card className="height-100 xs-100-vh">
                        <CardHeader>Regex Pal</CardHeader>
                        <CardBody>
                            <RegexPal></RegexPal>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="mb-5 col-xxl-6" lg="12" xl="8">
                    <Card className="height-100">
                        <CardHeader>Time Converter</CardHeader>
                        <CardBody>
                            <TimeConverter></TimeConverter>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="mb-5 col-xxl-6" lg="12" xl="4">
                    <Card className="mb-4">
                        <CardHeader>Base64 Decoder</CardHeader>
                        <CardBody>
                            <Base64Encoder></Base64Encoder>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>URI Decoder</CardHeader>
                        <CardBody>
                            <UrlDecoder></UrlDecoder>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardHeader>Code Diff</CardHeader>
                        <CardBody>
                            <CodeDiff></CodeDiff>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default App;
