import React from 'react';
import {PureComponent} from 'react'
import CodeMirror from 'codemirror/lib/codemirror'
import './CodeDiff.scss';
require('codemirror/lib/codemirror.css');
require('codemirror/addon/merge/merge.css');
require('codemirror/addon/merge/merge');

class CodeDiff extends PureComponent {

    componentDidMount() {
        CodeMirror.MergeView(this._ref, {
            origLeft: null, //left
            value: '', //center
            origRight: '', //right
            allowEditingOriginals: true,
            lineNumbers: true,
            highlightDifferences: true,
            gutters: ["CodeMirror-linenumbers", 'CodeMirror-lint-markers'],
            lint: false,
            connect: 'align',
        })
    }

    render() {
        return (
            <div className="code-diff">
                <div ref={ref => this._ref = ref}></div>
            </div>
        )
    }
}

export default CodeDiff