import React, {useState, useEffect, useCallback} from 'react';
import {Row, Col, Input, Label, FormGroup} from 'reactstrap';
import './TimeConverter.scss';
import * as moment from 'moment';
import "moment-timezone";

function TimeConverter() {
    const [milliseconds, setMilliseconds] = useState(0);
    const [millisecondsFieldValue, _setMillisecondsFieldValue] = useState(milliseconds);
    const displayZones = [{
        timeZone: 'US/Pacific',
        abbreviation: 'pst'
    }, {
        timeZone: 'US/Mountain',
        abbreviation: 'mst'
    }, {
        timeZone: 'US/Central',
        abbreviation: 'cst'
    }, {
        timeZone: 'US/Eastern',
        abbreviation: 'est'
    }];

     useEffect(() => {
        // fetchTime('utc');
        fetchTime('Etc/GMT');
    }, []);


    useEffect(() => {
        _setMillisecondsFieldValue(milliseconds)
    }, [milliseconds]);

    const setMillisecondsFieldValue = (time) => {
        _setMillisecondsFieldValue(time);

        const m = moment(time);
        if (m.isValid()) {
            setMilliseconds(parseInt(m.format('x')));
        }
    }

    const fetchTime = (timeZone) => {
        //const url = 'https://worldclockapi.com/api/json/' + timeZone + '/now';
        const url = 'https://worldtimeapi.org/api/timezone/'+timeZone;
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    debugger

                    //setMilliseconds(parseInt(moment(result.currentDateTime).tz('utc').format('x')));

                    setMilliseconds(parseInt(moment(result.unixtime * 1000).tz('utc').format('x')));
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setMilliseconds(parseInt(moment(new Date().getTime()).tz('utc').format('x')));
                }
            )
    }

    const updateMilliseconds = (milliseconds) => {
        setMillisecondsFieldValue(milliseconds)
        const timeStamp = !isNaN(milliseconds) ? parseInt(milliseconds) : milliseconds;
        const m = moment(timeStamp);

        if (m.isValid()) {
            setMilliseconds(parseInt(m.format('x')));
        }
    }

    return (
        <div className="time-converter">
            <Row>
                <Col>
                    <FormGroup className="mb-3">
                        <Label for="utc">UTC</Label>
                        <Input type="input" name="utc_time" id="utc"
                               onChange={(event) => updateMilliseconds(event.target.value)}
                               value={millisecondsFieldValue}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                {displayZones.map(function(zoneProps, i){
                    return <ZoneColumn milliseconds={milliseconds} key={i} abbreviation={zoneProps.abbreviation}
                                       timeZone={zoneProps.timeZone} setMilliseconds={setMilliseconds}/>
                })}
            </Row>
        </div>
    )
}

function ZoneColumn(props) {
    const {milliseconds, abbreviation, timeZone, setMilliseconds} = props;
    const [formattedTime, _setFormattedTime] = useState('');
    const [fieldValue, setFieldValue] = useState('');
    const formats = [{
        text: 'Date',
        format: 'YYYY-MM-DD'
    }, {
        text: 'Time',
        format: 'HH:mm:ss'
    }, {
        text: 'Offset',
        format: 'Z'
    }, {
        text: 'DST',
        format: 'isDST'
    }];

    const formatTime = useCallback((timeZone, format = 'YYYY-MM-DD HH:mm:ss') => { //YYYY-MM-DD HH:mm:ss
        const m = moment.tz(milliseconds, timeZone);
        return format === 'isDST' ? m.isDST() : m.format(format);
    }, [milliseconds])

    const setFormattedTime = (time) => {
        _setFormattedTime(time);
        const m = moment.tz(time, timeZone);

        if (m.isValid()) {
            setMilliseconds(parseInt(m.format('x')));
        }
    }

    useEffect(() => {
        _setFormattedTime(formatTime(timeZone))
    }, [formatTime, timeZone]);

    useEffect(() => {
        setFieldValue(formattedTime);
    }, [formattedTime]);

    return (
        <Col xs="12" sm="6" md="3" className="mb-3 mb-md-0">
            <FormGroup>
                <Label for={abbreviation}>{timeZone}</Label>
                <Input type="input" onChange={(event) => setFieldValue(event.target.value)}
                       onBlur={(event) => setFormattedTime(event.target.value)}
                       value={fieldValue} name={abbreviation + '_time'}
                       id={abbreviation} />
            </FormGroup>
            {formats.map(function(formatProps, i){
                return <ZoneRow milliseconds={milliseconds} key={i} text={formatProps.text}
                                format={formatProps.format} timeZone={timeZone} />
            })}
        </Col>
    )
}

function ZoneRow(props) {

    const {text, format, timeZone, milliseconds} = props;

    const getFormattedTime = (timeZone, format = '') => {
        const m = moment(milliseconds).tz(timeZone);
        return format === 'isDST' ? m.isDST() : m.format(format);
    }

    const getRowControl = (timeZone, format) => {
        if (format === 'isDST') {
            return getFormattedTime(timeZone, format) ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>;
        }

        return <span>{getFormattedTime(timeZone, format)}</span>;
        //return <Input type="input" plaintext={true} readOnly={true} value={getFormattedTime(timeZone, format)}/>;
    }

    return (
            <div className="d-flex flex-row">
                <Label className="mr-auto text-nowrap">{text}</Label>
                <label className="text-nowrap">{getRowControl(timeZone, format)}</label>
            </div>
    )
}

export default TimeConverter;
