import React, { useState } from 'react';
import { Button, Input, FormFeedback, FormGroup } from 'reactstrap';
import './Base64Encoder.scss';
import '../../index.scss';

function Base64Encoder() {
    const [text, setText] = useState('');
    const [isValid, setIsValid] = useState(true);

    const encode = function(){
        setText(btoa(text));
        setIsValid(true);
    }

    const decode = function(){
        try {
            setText(atob(text));
            setIsValid(true);
        } catch(e) {
            setIsValid(false);
           //console.log(e);
        }
    }

    return(
        <div className="base64-encoder">
            <FormGroup className="mb-3">
                <Input invalid={!isValid} type="textarea" placeholder="" onChange={(event)=>setText(event.target.value)} value={text} name="text" />
                <FormFeedback>The string to be decoded is not correctly encoded.</FormFeedback>
            </FormGroup>

            <Button color="primary" onClick={encode} className="mr-3 encode-btn">Base64 Encode</Button>
            <Button color="primary" onClick={decode} className="decode-btn">Base64 Decode</Button>
        </div>
    )
}
export default Base64Encoder;
