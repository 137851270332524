import React from 'react';
import {FormGroup, Input, CustomInput, Label} from "reactstrap";
import XRegExp from './xregexpp.js';
import RegexPalParser from  './RegexPalParser.js';
import './regex_pal.scss';

class RegexPal extends React.Component {

    constructor(props){
        super(props);

        this.handleFlagChange = this.handleFlagChange.bind(this);
        this.updateTextArea = this.updateTextArea.bind(this);

        this.state = {
            search:'\\d{5}',
            input:'02101',

            searchBg:'',
            inputBg:'',

            flags: {
                g: true,
                i: true,
                m: true,
                s: true
            },

            highlightSyntax: true,
            highlightMatches: true,
            invertMatches: false
        };
    }

    componentDidMount() {
        this.runCheck();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        this.runCheck();
    }

    runCheck(){
        this.highlightSearchSyntax();
        this.highlightMatches();
    }

    updateTextArea(e){
        const currentText = this.state.search;
        const newText = e.target.value;
        const fieldName = e.target.name;

        if(currentText !== newText){
            this.setState({[fieldName]:newText});
        }
    }

    handleFlagChange(e){
        const flagName = e.target.name;
        const checked = e.target.checked;
        const flagsCopy = Object.assign({}, this.state.flags);

        flagsCopy[flagName] = checked;
        this.setState({flags:flagsCopy});
    }

    clearBg (fieldName) {
        const currentVal = this.state[fieldName];
        const fieldVal = currentVal.replace(XRegExp.cache("[<&>]", "g"), "_");

        if(currentVal !== fieldVal){
            this.setState({[fieldName]:fieldVal});
        }
    }

    highlightMatches(){
        const search = this.state.search;
        const searchBg =this.state.searchBg;

        const input = this.state.input;
        const currentInputBg = this.state.inputBg;

        const flags = this.state.flags;

        const highlightMatches = this.state.highlightMatches;
        const invertMatches = this.state.invertMatches;

        /* Abort if the user's regex contains an error (the test regex accounts for IE's changes to innerHTML).
        The syntax highlighting catches a number of mistakes and cross-browser issues which might not cause the
        browser to throw an error. Also abort if the search is empty and not using the invert results option, or
        if match highlighting is disabled. */
        if (
            XRegExp.cache('<[bB] class="?err"?>').test(searchBg) ||
            (!search.length && !invertMatches) ||
            !highlightMatches
        ) {
            this.clearBg('input');
            return;
        }

        let searchRegex = null;
        try {
            /* If existing, a single trailing vertical bar (|) is removed from the regex which is to be applied
            to the input text. This behavior is copied from RegexBuddy, and offers faster results and a less
            surprising experience while the user is in the middle of creating a regex. */
            searchRegex = new XRegExp(re.sansTrailingAlternator.exec(search)[0],
                (flags.g ? "g" : "") +
                (flags.i ? "i" : "") +
                (flags.m ? "m" : "") +
                (flags.s ? "s" : "")
            );

            /* An error should never be thrown if syntax highlighting and XRegExp are working correctly, but the
            potential is avoided nonetheless. Safari in particular has several strange bugs which cause its regex
            engine's parser to barf during compilation. */
        } catch(err){
            this.clearBg('input');
            return;
        }


        // Matches are never looped over, for performance reasons...

        /* Initially, "`~{...}~`" is used as a safe string to encapsulate matches. Note that if such an
        unlikely sequence appears in the text, you might receive incorrect results. */
        let output = '';
        if (invertMatches) {
            output = ("`~{" +
                input.replace(searchRegex, "}~`$&`~{") +
                "}~`")
                /* Remove zero-length matches, and combine adjacent matches */
                .replace(XRegExp.cache("`~\\{\\}~`|\\}~``~\\{", "g"), "");
        } else {
            output = input.replace(searchRegex, "`~{$&}~`");
        }

        /* Put all matches within alternating <b> and <i> elements (short element names speed up markup
        generation). Angled brackets and ampersands are first replaced, to avoid unintended HTML markup
        within the background <pre> element. */
        output = output
            .replace(XRegExp.cache("[<&>]", "g"), "_")
            .replace(re.matchPair, "<b>$1</b>$2<i>$3</i>");

        if(currentInputBg !== output){
            this.setState({inputBg:output});
        }
    }

    highlightSearchSyntax() {
        const highlightSyntax = this.state.highlightSyntax;
        const search = this.state.search;
        const currentSearchBg = this.state.searchBg;

        if (highlightSyntax) {
            const newSearchBg = RegexPalParser.parse(search);
            if(currentSearchBg !== newSearchBg){
                this.setState({searchBg:newSearchBg});
            }
        } else {
            this.clearBg('search');
        }
    }

    render() {
        return (
            <div className="regex-pal">
                <FormGroup className="mb-3">
                    <Label check className="mr-4">
                        <CustomInput
                            type="checkbox"
                            id="flag-global"
                            label="Global"
                            name="g"
                            checked={this.state.flags.g}
                            onChange={this.handleFlagChange}
                        />
                    </Label>
                    <Label check className="mr-4">
                        <CustomInput
                            type="checkbox"
                            id="flag-i"
                            name="i"
                            label="Case Insensitive"
                            checked={this.state.flags.i}
                            onChange={this.handleFlagChange}
                        />
                    </Label>
                    <Label check className="mr-4">
                        <CustomInput
                            type="checkbox"
                            id="flag-m"
                            name="m"
                            label="Multiline"
                            checked={this.state.flags.m}
                            onChange={this.handleFlagChange}
                        />
                    </Label>
                    <Label check className="mr-4">
                        <CustomInput
                            type="checkbox"
                            id="flag-s"
                            name="s"
                            label="Dot Matches Everything"
                            checked={this.state.flags.s}
                            onChange={this.handleFlagChange}
                        />
                    </Label>
                </FormGroup>


                <FormGroup className="smart-field smart-field-search">
                    <pre dangerouslySetInnerHTML={{__html: this.state.searchBg}}></pre>
                    <Input type="input" placeholder="" spellCheck={false} name="search" onChange={this.updateTextArea} value={this.state.search} />
                </FormGroup>
                <FormGroup className="smart-field smart-field-input">
                    <pre dangerouslySetInnerHTML={{__html: this.state.inputBg}}></pre>
                    <Input type="textarea" placeholder="" spellCheck={false} name="input" onChange={this.updateTextArea} value={this.state.input} />
                </FormGroup>
            </div>
        )
    }
}

const re = {
    /* If the matchPair regex seems a little crazy, the theory behind it is that it will be faster than using lazy quantification */
    matchPair: /`~\{((?:[^}]+|\}(?!~`))*)\}~`((?:[^`]+|`(?!~\{(?:[^}]+|\}(?!~`))*\}~`))*)(?:`~\{((?:[^}]+|\}(?!~`))*)\}~`)?/g,
    sansTrailingAlternator: /^(?:[^\\|]+|\\[\S\s]?|\|(?=[\S\s]))*/
};

export default RegexPal
