import React from 'react';
import 'codemirror';
import minify from 'jsonminify';
import {Controlled as CodeMirror} from 'react-codemirror2'
import {Button,  Alert} from 'reactstrap';
import {js_beautify} from 'js-beautify'

import './JsonFormatter.scss';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

//require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');

const jsonlint = require('jsonlint-mod');

class JsonFormatter extends React.Component {
    constructor(props) {
        super(props);

        this.textInput1 = React.createRef();

        this.prettyPrint = this.prettyPrint.bind(this);
        this.compress = this.compress.bind(this);

        this.state = {
            inputText1:'',
            inputText2:'',
            error:{},

            cmOptions:  {
                lineNumbers: true,
                readOnly: false,
                mode: 'javascript',
                viewportMargin: Infinity,
                gutters: ["breakpoints", "CodeMirror-linenumbers"]
            }
        };
    }

    //bound
    prettyPrint(){
        let inputText1 = this.state.inputText1;
        inputText1 = js_beautify(inputText1);

        const error = this.parseError(inputText1);
        
        this.setState({
            inputText1:inputText1,
            error:error
        })
    }

    //bound
    compress(){
        let inputText1 = this.state.inputText1;
        inputText1 = js_beautify(inputText1);

        const error = this.parseError(inputText1);

        if(!error.msg){
            inputText1 = minify(inputText1);
        }

        this.setState({
            inputText1:inputText1,
            error:error
        })
    }

    componentDidUpdate(prevProps,prevState,snapshot){
        if(prevState.error.line) {
            this.removeMarkers(prevState.error.line);
        }

        if(this.state.error.line){
           this.markErrorLine(this.state.error);
        }
    }

    removeMarkers(line){
        const lineNumber = line - 1;
        const cm = this.textInput1.current.editor;

        cm.clearGutter('breakpoints');
        cm.removeLineClass(lineNumber, 'background', 'error-line');
    }

    markErrorLine(error){
        const lineNumber = error.line - 1;
        const marker = this.createMarker();
        const cm = this.textInput1.current.editor;

        cm.setGutterMarker(lineNumber, "breakpoints", marker);
        cm.addLineClass(lineNumber, 'background', 'error-line');
    }

    createMarker() {
        const marker = document.createElement("div");
        marker.className = 'error-marker';
        marker.innerHTML = "<i class=\"fas fa-times-circle\"></i>";
        return marker;
    }

    parseError(str){
        try {
            jsonlint.parse(str);
            return {};
        } catch(e){
            const msg = e.message;
            const lineNumber = this.parseLineNumberFromError(e.message);
            return {
                line:lineNumber,
                msg:msg
            };
        }
    }

    parseLineNumberFromError(message){
        const found = message.match(/[0-9]+/g);
        return parseInt(found[0]);
    }

    render() {
        return  (

            <div className="json-formatter d-flex flex-column height-100">
                <div className="flex-grow-1 position-relative mb-3">

                        <CodeMirror
                            className="height-100 position-relative"
                            ref={this.textInput1}
                            value={this.state.inputText1}
                            options={this.state.cmOptions}
                            onBeforeChange={(editor, data, value) => {this.setState({inputText1:value})}}
                        />

                </div>

                <div className="flex-shrink-1 mb-3">
                    <Alert color="danger" isOpen={!!this.state.error.msg} className="mb-0">
                        <pre>{this.state.error.msg}</pre>
                    </Alert>
                </div>
                <div className="flex-shrink-1">
                    <Button color="primary" onClick={this.prettyPrint} className="mr-3">Validate</Button>
                    <Button color="primary" onClick={this.compress}>Compress</Button>
                </div>
            </div>
        );
    }
}

export default JsonFormatter;